import {
  Button,
  Dialog,
  Stack,
  Typography
} from "@mui/material";
import { MUI_COLOR_TYPE } from "../../config/Constants";

interface CustomConfirmDialogProps {
  open: boolean;
  title?: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  color: MUI_COLOR_TYPE;
}

export default function CustomConfirmDialog({
  open,
  title = "",
  message,
  onConfirm,
  onCancel,
  color = "primary",
}: CustomConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      fullWidth
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "transparent",
            // backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
      PaperProps={{
        style: {
          //   boxShadow: "none",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          position: "absolute", // 위치를 절대값으로 설정
          top: "35%", // 화면 상단에서 10% 위치
          left: "50%", // 화면의 가로 중앙
          transform: "translate(-50%, 0)", // 가로 중심 맞춤
        },
      }}
    >
      <Stack py={2} px={3}>
        <Stack>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body1" style={{ marginTop: "0px" }}>
            {message}
          </Typography>
        </Stack>
        <Stack flexDirection={"row"} gap={1}>
          <Button
            fullWidth
            onClick={onCancel}
            variant="outlined"
            color={color}
            size="small"
          >
            취소
          </Button>
          <Button
            fullWidth
            onClick={onConfirm}
            variant="contained"
            color={color}
            size="small"
          >
            확인
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
