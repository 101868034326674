import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import HeaderBar from "./components/common/HeaderBar";
import SideBar from "./components/common/SideBar";
import ProtectedRoute from "./components/ProtectedRoute";
import ContentScreen from "./screens/ContentScreen";
import DashboardScreen from "./screens/DashboardScreen";
import LinkScreen from "./screens/LinkScreen";
import LoginScreen from "./screens/LoginScreen";
import MuiScreen from "./screens/MuiScreen";
import MyPageScreen from "./screens/MyPageScreen";
import { RootState } from "./store/reducer";

function AppInner() {
  const { username } = useSelector((state: RootState) => state.userReducer);
  const isAuthorized = username !== "";

  return (
    <div className="container">
      <Router>
        {isAuthorized && <SideBar />}
        <div className="inner-container">
          <Stack>{isAuthorized && <HeaderBar />}</Stack>
          <Routes>
            <Route
              path="/login"
              element={!isAuthorized ? <LoginScreen /> : <Navigate to="/" />}
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <DashboardScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mui"
              element={
                <ProtectedRoute>
                  <MuiScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/link/:groupId"
              element={
                <ProtectedRoute>
                  <LinkScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/content/:groupId"
              element={
                <ProtectedRoute>
                  <ContentScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage"
              element={
                <ProtectedRoute>
                  <MyPageScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<Navigate to={isAuthorized ? "/" : "/login"} />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default AppInner;
