export function formatNumber(value: string | number): string {
    // 숫자 또는 숫자 형식의 문자열이 아닌 경우 빈 문자열 반환
    if (value === null || value === undefined || isNaN(Number(value))) {
        return "";
    }

    // 숫자로 변환 후 천 단위 쉼표 추가
    return Number(value).toLocaleString();
}

export function cleanIfNumberWithCommas(value: string): string {
    // 숫자와 쉼표로만 이루어진지 확인
    if (/^\d{1,3}(,\d{3})*$/.test(value)) {
        return value.replace(/,/g, ""); // 쉼표 제거
    }
    return value; // 숫자 + 쉼표가 아닌 경우 원본 반환
}