import ApexAreaChart from "../components/charts/ApexAreaChart";
import ApexBarChart from "../components/charts/ApexBarChart";
import ApexLineChart from "../components/charts/ApexLineChart";
import ApexPieChart from "../components/charts/ApexPieChart";
import ScreenContainer from "../components/ui/ScreenContainer";

export default function DashboardScreen() {
  return (
    <ScreenContainer>
      <div className="chart-row">
        <div className="chart-shape-box">
          <ApexAreaChart type="area" />
        </div>
        <div className="chart-pie-shape-box">
          <ApexPieChart />
        </div>
      </div>
      <div className="chart-row">
        <div className="chart-shape-box">
          {/* <ApexPieChart /> */}
          <ApexBarChart />
        </div>
        <div className="chart-shape-box">
          <ApexLineChart type="line" />
        </div>
      </div>
    </ScreenContainer>
  );
}
