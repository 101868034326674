type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

export const handleInputChange = <T>(
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  setState: Setter<T>
) => {
  const { id, value } = e.target;

  setState((prev) => ({
    ...prev,
    [id]: value,
  }));
};