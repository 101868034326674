import React from "react";
import "./CustomCheckbox.css";
import { Stack, Typography, useTheme } from "@mui/material";

interface CustomCheckboxProps {
  id?: string | undefined;
  title?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  require?: boolean;
  checkColor?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  id = "custom-checkbox", // 기본 id 설정
  title = "",
  checked = false,
  onChange,
  require = false,
  checkColor = ''
}) => {
  const theme = useTheme();

  return (
    <label
      htmlFor={id}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer", // 클릭 가능한 UI로 변경
        height: "100%",
        userSelect: "none",
        gap: 2,
      }}
    >
      <input
        id={id}
        type="checkbox"
        className="custom-checkbox"
        checked={checked}
        onChange={onChange}
        style={
          {
            "--checkbox-background-color": checkColor === ''  ? theme.palette.primary.light : checkColor,
          } as React.CSSProperties
        } // 스타일로 전달
      />
      <Typography variant="h5">{title}</Typography>
      {require && (
        <Typography variant="h5" color="error">
          (필수)
        </Typography>
      )}
    </label>
  );
};

export default CustomCheckbox;
