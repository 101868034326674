// export const API_BASE_URL = "https://api.example.com";
// export const DEFAULT_LANGUAGE = "en";
// export const ITEMS_PER_PAGE = 10;
// export const THEME_COLORS = {
//   primary: "#3498db",
//   secondary: "#2ecc71",
// };
export const COMPANY_NAME = "MyCompany";

export const SIDE_BAR_WIDTH = "220px";

export type MUI_COLOR_TYPE = "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";