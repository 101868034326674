import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { Button, IconButton, Stack, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import envSlice from "../../slices/env";
import userSlice from "../../slices/user";
import { useAppDispatch } from "../../store";
import { RootState } from "../../store/reducer";
import SettingSelect from "../ui/SettingSelect";
import { useNavigate } from "react-router-dom";
import { http } from "../../config/axios-config";

export default function HeaderBar() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { mode } = useSelector((state: RootState) => state.envReducer);
  const navigate = useNavigate();

  const onClickMyPage = () => {
    navigate("/mypage");
  };

  const onClickLogout = () => {
    try {
      http.post("/users/logout/");
    } catch (error) {
      // errorHandler(error);
    } finally {
      dispatch(userSlice.actions.setUserLogout());
    }
  };

  const toggleColorMode = () => {
    dispatch(envSlice.actions.setMode());
  };

  return (
    <Stack
      flexDirection={"row"}
      padding={"5px 20px"}
      justifyContent={"space-between"}
      minWidth={1000}
      style={{
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <Stack justifyContent={"center"}>
        <IconButton
          aria-label="themeConvertButton"
          size="medium"
          onClick={toggleColorMode}
        >
          {mode === "dark" ? (
            <DarkModeOutlinedIcon
              fontSize="inherit"
              sx={{ color: theme.palette.text.primary, fontSize: 18 }}
            />
          ) : (
            <WbSunnyOutlinedIcon
              fontSize="inherit"
              sx={{ color: theme.palette.text.primary, fontSize: 18 }}
            />
          )}
        </IconButton>
      </Stack>
      <Stack flexDirection={"row"} gap={1}>
        <SettingSelect />
        <Button variant="text" size="small" onClick={onClickMyPage}>
          마이페이지
        </Button>
        <Button variant="text" size="small" onClick={onClickLogout}>
          로그아웃
        </Button>
      </Stack>
    </Stack>
  );
}
