import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { popupCustomAlert } from "../CustomToast";
import CustomCheckbox from "../ui/CustomCheckbox";
import { errorHandler, http } from "../../config/axios-config";
import { handleInputChange } from "../lib/handleInputChange";

interface UpdatePasswordType {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
}

export default function UpdatePasswordPopup({ onSubmit, onCancel }: Props) {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState<UpdatePasswordType>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  /**
   * 컴포넌트 제어
   */
  const handleSubmit = async () => {
    if (
      user.currentPassword === "" ||
      user.newPassword === "" ||
      user.confirmPassword === ""
    ) {
      popupCustomAlert("warn", "정보를 모두 입력해주세요");
      return;
    } else if (user.newPassword !== user.confirmPassword) {
      popupCustomAlert("warn", "변경 비밀번호 확인이 일치하지 않습니다");
      return;
    }

    try {
      await http.post("/users/setPw/", {
        current_password: user.currentPassword,
        new_password: user.newPassword,
      });

      popupCustomAlert("success", "비밀번호 변경을 완료했습니다");
      onSubmit();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="regist-user-dialog-title"
      aria-describedby="regist-user-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={"xs"}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <Stack flexDirection={"row"} height={310}>
        <Stack flex={1} px={7} py={2} gap={2}>
          <Stack alignItems={"center"}>
            <Typography variant="subtitle1">비밀번호 변경</Typography>
          </Stack>

          <Stack gap={1}>
            <Stack flexDirection={"row"} gap={1}>
              <Typography variant="h5">현재 비밀번호</Typography>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                size="small"
                fullWidth
                id="currentPassword"
                value={user.currentPassword}
                onChange={(e) =>
                  handleInputChange<UpdatePasswordType>(e, setUser)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityIcon fontSize="small" />
                      ) : (
                        <VisibilityOffIcon fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Stack flexDirection={"row"} gap={1}>
              <Typography variant="h5">변경 비밀번호</Typography>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                size="small"
                fullWidth
                id="newPassword"
                value={user.newPassword}
                onChange={(e) =>
                  handleInputChange<UpdatePasswordType>(e, setUser)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityIcon fontSize="small" />
                      ) : (
                        <VisibilityOffIcon fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Stack flexDirection={"row"} gap={1}>
              <Typography variant="h5">변경 비밀번호 확인</Typography>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                size="small"
                fullWidth
                id="confirmPassword"
                value={user.confirmPassword}
                onChange={(e) =>
                  handleInputChange<UpdatePasswordType>(e, setUser)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityIcon fontSize="small" />
                      ) : (
                        <VisibilityOffIcon fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Stack>
          </Stack>

          <Stack flexDirection={"row"} gap={1}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              닫기
            </Button>
            <Button fullWidth variant="contained" onClick={handleSubmit}>
              변경하기
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
