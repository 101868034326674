import { Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";

interface Props {
  children: React.ReactNode;
}

// .screen-container {
//     display: flex;
//     flex-direction: column;
//     flex: 1;
//     padding: 15px 20px;
//     overflow: auto;
//     background-color: #eff6ff;
//   }

export default function ScreenContainer({ children }: Props) {
  const { mode } = useSelector((state: RootState) => state.envReducer);
  return (
    <Stack
      flex={1}
      padding={5}
      bgcolor={mode === "light" ? "#eff6ff" : "#464646"}
      minWidth={1000}
    >
      {children}
    </Stack>
  );
}
