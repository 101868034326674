import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";
import { useTheme } from "@mui/material";

interface Option<T> {
  value: T;
  label: string;
  color?: string;
}

interface Props<T> {
  value: T;
  options: Option<T>[];
  onChange: React.Dispatch<React.SetStateAction<T>>;
  width?: number; // 드롭다운 너비
  height?: number; // 드롭다운 높이
}

export default function PageSizeSelect<T>({
  value,
  options,
  onChange,
  width = 100, // 기본 너비
  height = 37, // 기본 높이
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 열림 상태
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const { mode } = useSelector((state: RootState) => state.envReducer);

  const handleToggle = () => setIsOpen((prev) => !prev); // 드롭다운 열고 닫기

  const handleSelect = (selectedValue: T) => {
    onChange(selectedValue); // 선택한 값 변경
    setIsOpen(false); // 드롭다운 닫기
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // 드롭다운 외부 클릭 시 닫기
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={{
        position: "relative",
        width,
        fontSize: "14px",
        fontFamily: "pretendardr",
      }}
    >
      <div
        onClick={handleToggle}
        style={{
          height,
          lineHeight: `${height}px`, // 중앙 정렬
          padding: "0 0px",
          border: mode === 'light' ? "1px solid #ccc" : "1px solid #777777", 
          borderRadius: "4px",
          cursor: "pointer",
          userSelect: "none", // 텍스트 선택 방지
          display: "flex",
          justifyContent: "center",
          gap: "3px",
          backgroundColor: mode === 'light' ? '#fff': '#2c2c2c',
        }}
      >
        {options.find((option) => option.value === value)?.label || ""}
        <div style={{ display: "flex", alignItems: "center" }}>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>

      {isOpen && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: "0",
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: mode === 'light' ? 'white': '#2c2c2c',
            listStyleType: "none",
            margin: "0",
            padding: "0",
            zIndex: 1000,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // 그림자 추가
          }}
        >
          {options.map((option) => (
            <li
              key={String(option.value)}
              onClick={() => handleSelect(option.value)}
              style={{
                padding: "10px",
                cursor: "pointer",
                // backgroundColor: option.value === value ? "#f8f8f8" : "#ffffff",
                borderBottom: "1px solid #eee",
                userSelect: "none", // 텍스트 선택 방지
                color: option.color,
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
