import { Button, Stack, TextField } from "@mui/material";
import styled from "styled-components";
import { useAppDispatch } from "../store";
import { useRef, useState } from "react";
import bg from "../assets/main.jpg";
import logo from "../assets/logo.png";
import { popupCustomAlert } from "../components/CustomToast";
import userSlice from "../slices/user";
import RegistUserPopup from "../components/popup/RegistUserPopup";
import { errorHandler, http } from "../config/axios-config";

export default function LoginScreen() {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const idRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleLogin = async () => {
    const id = idRef.current?.value;
    const password = passwordRef.current?.value;

    if (!id) {
      popupCustomAlert("info", "아이디를 입력해주세요");
      return;
    }

    if (!password) {
      popupCustomAlert("info", "비밀번호를 입력해주세요");
      return;
    }

    try {
      const response = await http.post("/users/login/", {
        username: id,
        password: password,
      });
      console.log(response.data);
      console.log(id);
      
      dispatch(
        userSlice.actions.setUserLogin({
          username: id,
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const onClickFindID = () => {
    popupCustomAlert("info", "전화 주세요");
  };

  const onClickSetPassword = () => {
    popupCustomAlert("info", "팝업 만들어야함");
  };

  const handleKeyPress = (
    event: React.KeyboardEvent,
    type: "id" | "password"
  ) => {
    if (event.key === "Enter") {
      if (type === "id") {
        passwordRef.current?.focus();
      } else if (type === "password") {
        handleLogin();
      }
    }
  };

  return (
    <Stack
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        flex: 1,
        justifyContent: "center",
      }}
    >
      {open && (
        <RegistUserPopup
          onSubmit={() => setOpen(false)}
          onCancel={() => setOpen(false)}
        />
      )}
      <Stack
        width={"300px"}
        height={"345px"}
        bgcolor={"white"}
        p={4}
        borderRadius={"10px"}
        gap={2}
      >
        <Stack
          width={"100%"}
          height={"100px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={logo} alt="Logo" height={"70px"} />
        </Stack>
        <Stack width={"100%"} gap={2} mt={"10px"}>
          <TextField
            id="loginId"
            inputRef={idRef}
            label="아이디"
            variant="outlined"
            fullWidth
            onKeyDown={(e) => handleKeyPress(e, "id")}
          />
          <TextField
            id="loginPassword"
            inputRef={passwordRef}
            label="비밀번호"
            variant="outlined"
            type="password"
            fullWidth
            onKeyDown={(e) => handleKeyPress(e, "password")}
          />
        </Stack>
        <Stack width={"100%"} gap={1}>
          <Button variant="contained" onClick={handleLogin}>
            로그인
          </Button>
          <Button variant="outlined" onClick={() => setOpen(true)}>
            회원가입
          </Button>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Button
              size="small"
              sx={{
                color: "#979797",
                fontSize: 12,
              }}
              onClick={() => onClickFindID()}
            >
              아이디 찾기
            </Button>
            <Button
              size="small"
              sx={{
                color: "#979797",
                fontSize: 12,
              }}
              onClick={() => onClickSetPassword()}
            >
              비밀번호 재설정
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
