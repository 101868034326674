import { useState } from "react";
import ApexChart from "react-apexcharts";
import { ChartContent, ChartTitle, ChartWarpper } from "../styled/CommonUI";

export default function ApexBarChart() {
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Sales",
      data: [
        12000, 5000, 900, 5000, 3000, 4200, 5000, 8000, 13000
      ],
    },
  ]);

  return (
    <ChartWarpper>
      <ChartTitle>Bar Chart</ChartTitle>
      <ChartContent>
        <ApexChart
          series={chartSeries}
          type={"bar"}
          // width={600}
          height={220}
          options={{
            chart: {
              offsetY: -20,
              // offsetX: 0,
              zoom: {
                enabled: true,
              },
              toolbar:{
                show:false
              },
              // background: "#ffa32d",
              animations: {
                enabled: true,
                easing: "easeout",
                speed: 1000,
                animateGradually: {
                  enabled: false,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            dataLabels: {
              enabled: false,
              background: {
                //   borderColor: "#e05151",
                // padding: 50,
                borderWidth: 0,
                borderRadius: 20,
                enabled: true,
                dropShadow: {
                  blur: 5,
                  color: "#53c8ff",
                  enabled: true,
                  left: 3,
                  opacity: 5,
                  top: 2,
                },
              },
              // textAnchor: "middle",
              // distributed: true,
            },
            legend: {
              show: true,
              position: "bottom",
              // offsetY: 20,
              // height: 0,
              // formatter: function (val, opts) {
              //   return val + " " + opts.w.globals.series[opts.seriesIndex];
              // },
            },
            plotOptions: {
              bar: {
                borderRadius: 2,
                borderRadiusApplication: "end",
                distributed: true,
              },
            },
            markers: {
              size: 0,
              // size: [20],
              // shape:'diamond',
              hover: {
                //   sizeOffset: 6,
              },
            },
            xaxis: {
              // floating:true,
              axisBorder: {
                show: true,
              },
              labels: {
                show: false,
              },
              categories: [
                "01 Jan",
                "02 Jan",
                "03 Jan",
                "04 Jan",
                "05 Jan",
                "06 Jan",
                "07 Jan",
                "08 Jan",
                "09 Jan",
              ],
            },
            yaxis: {
              tickAmount: 3,
              reversed: false,
            },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val + " (Hello)";
                    },
                  },
                },
                {
                  title: {
                    formatter: function (val) {
                      return val + " per session";
                    },
                  },
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    },
                  },
                },
              ],
            },
            grid: {
              // borderColor: "#e09797",
              // column: {
              //   colors: ["#0000ff", "#00ff00", "#ff0000"],
              //   opacity: 5,
              // },
              padding: {
                top: 20,
                // bottom: 0,
                // left: 20,
                // right: 20,
              },
            },
          }}
        />
      </ChartContent>
    </ChartWarpper>
  );
}
