import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import {
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UpdatePasswordPopup from "../components/popup/UpdatePasswordPopup";
import ScreenContainer from "../components/ui/ScreenContainer";
import { errorHandler, http } from "../config/axios-config";
import { RootState } from "../store/reducer";
import { ProfileResponseType, ProfileType } from "../types/ProfileType";
import { handleInputChange } from "../components/lib/handleInputChange";

export default function MyPageScreen() {
  const theme = useTheme();
  const { username } = useSelector((state: RootState) => state.userReducer);
  const [open, setOpen] = useState(false);
  const [origin, setOrigin] = useState<ProfileType>();
  const [profile, setProfile] = useState<ProfileType>({
    username,
    email: "",
    is_agency: false,
    n_access_key: "",
    n_secret_key: "",
    n_master_id: "",
    nickname: "",
    phone: "",
    price_level: "",
    syncTm: new Date(),
    tax_id: "",
  });

  const getProfile = async () => {
    try {
      const response = await http.get("/profiles/");
      const data: ProfileResponseType[] = response.data;
      const info: ProfileType = {
        username: data[0].user.username,
        email: data[0].user.email,
        is_agency: data[0].is_agency,
        n_access_key: data[0].n_access_key,
        n_master_id: data[0].n_master_id,
        n_secret_key: data[0].n_secret_key,
        nickname: data[0].nickname,
        phone: data[0].phone,
        price_level: data[0].price_level,
        tax_id: data[0].tax_id,
        syncTm: data[0].syncTm,
      };
      setProfile(info);
      setOrigin(info);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <ScreenContainer>
      {open && (
        <UpdatePasswordPopup
          onSubmit={() => setOpen(false)}
          onCancel={() => setOpen(false)}
        />
      )}
      <Stack flex={1} flexDirection={"row"} gap={5}>
        <Stack flex={2} bgcolor={theme.palette.background.paper}>
          {profile !== undefined && (
            <>
              <Stack flexDirection={"row"} py={3} px={5} gap={1}>
                <Typography variant="caption">기본 정보</Typography>
                <Stack justifyContent={"center"}>
                  <CheckCircleOutlinedIcon
                    color="primary"
                    sx={{
                      fontSize: 15,
                    }}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Stack flexDirection={"row"} px={5} py={3} gap={5}>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">아이디</Typography>
                  <TextField size="small" fullWidth value={username} />
                </Stack>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">이메일</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    value={profile.email}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
              </Stack>

              <Stack flexDirection={"row"} py={3} px={5} gap={1}>
                <Typography variant="caption">추가 정보</Typography>
                <Stack justifyContent={"center"}>
                  <RadioButtonUncheckedOutlinedIcon
                    color="error"
                    sx={{
                      fontSize: 15,
                    }}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Stack flexDirection={"row"} px={5} py={3} gap={5}>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">이름</Typography>
                  <TextField
                    id="nickname"
                    size="small"
                    fullWidth
                    value={profile.nickname}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">전화번호</Typography>
                  <TextField
                    size="small"
                    id="phone"
                    fullWidth
                    value={profile.phone}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
              </Stack>
              <Stack flexDirection={"row"} px={5} py={3} gap={5}>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">사업자등록번호</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={"응답값 필요"}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">세금계산서용 이메일</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={"tax_id?"}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
              </Stack>

              <Stack flexDirection={"row"} py={3} px={5} gap={1}>
                <Typography variant="caption">검색광고 정보</Typography>
                <Stack justifyContent={"center"}>
                  <RadioButtonUncheckedOutlinedIcon
                    color="error"
                    sx={{
                      fontSize: 15,
                    }}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Stack flexDirection={"row"} px={5} py={3} gap={5}>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">CUSTOMER_ID</Typography>
                  <TextField
                    id="n_master_id"
                    size="small"
                    fullWidth
                    value={profile.n_master_id}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
                <Stack flexDirection={"column"} flex={1} gap={1}></Stack>
              </Stack>
              <Stack flexDirection={"row"} px={5} py={3} gap={5}>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">ACCESS KEY</Typography>
                  <TextField
                    id="n_access_key"
                    size="small"
                    fullWidth
                    value={profile.n_access_key}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
              </Stack>
              <Stack flexDirection={"row"} px={5} py={3} gap={5}>
                <Stack flexDirection={"column"} flex={1} gap={1}>
                  <Typography variant="h6">SECRET KEY</Typography>
                  <TextField
                    id="n_secret_key"
                    size="small"
                    fullWidth
                    value={profile.n_secret_key}
                    onChange={(e) =>
                      handleInputChange<ProfileType>(e, setProfile)
                    }
                  />
                </Stack>
              </Stack>
            </>
          )}
          <Stack
            flex={1}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"end"}
            py={3}
            px={5}
          >
            <Stack flex={1} flexDirection={"row"} gap={1} alignItems={"center"}>
              <InfoOutlinedIcon
                color="error"
                sx={{
                  fontSize: 16,
                }}
              />
              <Typography variant="h5">
                모든 정보 입력 후, 비딩이지를 사용하실 수 있습니다
              </Typography>
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              <Button variant="outlined" onClick={() => setOpen(true)}>
                비밀번호 변경
              </Button>
              <Button variant="outlined">초기화</Button>
              <Button variant="contained">저장하기</Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ScreenContainer>
  );
}
