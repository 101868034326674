import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { http } from "../../config/axios-config";
import { useAppDispatch } from "../../store";
import userSlice from "../../slices/user";

export default function SettingSelect() {
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 열림 상태
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const { mode } = useSelector((state: RootState) => state.envReducer);
  const { username, email } = useSelector(
    (state: RootState) => state.userReducer
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleToggle = () => setIsOpen((prev) => !prev); // 드롭다운 열고 닫기

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // 드롭다운 외부 클릭 시 닫기
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (type: "mypage" | "logout") => {
    setIsOpen(false);
    if (type === "mypage") {
      navigate("/mypage");
    } else if (type === "logout") {
      try {
        http.post("/users/logout/");
      } catch (error) {
        // errorHandler(error);
      } finally {
        dispatch(userSlice.actions.setUserLogout());
      }
    }
  };

  return (
    <div
      ref={dropdownRef}
      style={{
        position: "relative",
        fontSize: "14px",
        fontFamily: "pretendardr",
        alignContent: "center",
      }}
    >
      <div
        onClick={handleToggle}
        style={{
          // height,
          // lineHeight: `${height}px`,
          padding: "0 0px",
          borderRadius: "4px",
          cursor: "pointer",
          userSelect: "none", // 텍스트 선택 방지
          display: "flex",
          justifyContent: "center",
          backgroundColor: mode === "light" ? "#fff" : "#2c2c2c",
        }}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <FaUser />
          <Typography variant="h5">{username}</Typography>
        </Stack>
      </div>

      {isOpen && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: "-50px",
            width: "120px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: mode === "light" ? "white" : "#2c2c2c",
            listStyleType: "none",
            margin: "0",
            padding: "0",
            zIndex: 1000,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // 그림자 추가
          }}
        >
          <li
            onClick={() => handleSelect("mypage")}
            style={{
              padding: "10px",
              cursor: "pointer",
              borderBottom: "1px solid #eee",
              userSelect: "none", // 텍스트 선택 방지
            }}
          >
            <Stack
              flexDirection={"row"}
              alignContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <ManageAccountsIcon
                fontSize="small"
                sx={{
                  width: 30,
                }}
              />
              <Typography variant="h5">마이페이지</Typography>
            </Stack>
          </li>

          <li
            onClick={() => handleSelect("logout")}
            style={{
              padding: "10px",
              cursor: "pointer",
              borderBottom: "1px solid #eee",
              userSelect: "none", // 텍스트 선택 방지
            }}
          >
            <Stack
              flexDirection={"row"}
              alignContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <LogoutIcon
                sx={{
                  fontSize: 16,
                  width: 30,
                }}
              />
              <Typography variant="h5">로그아웃</Typography>
            </Stack>
          </li>
        </ul>
      )}
    </div>
  );
}
