import { createTheme } from "@mui/material";
import { koKR } from "@mui/x-data-grid/locales";

const lightTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#3168e0",
        contrastText: "#fff",
      },
      secondary: {
        main: "#dc004e",
        contrastText: "#000",
      },
      background: {
        default: "#ffffff",
        paper: "#fff",
      },
      text: {
        primary: "#333",
        secondary: "#666",
        disabled: "#999",
      },
      error: {
        main: "#EE0000",
      },
      warning: {
        main: "#FFB724",
        contrastText: "#fff",
      },
      info: {
        main: "#29b6f6",
      },
      success: {
        main: "#37b75d",
      },
      action: {
        active: "#001E3C",
        hover: "rgba(0, 30, 60, 0.08)",
        selected: "rgba(0, 30, 60, 0.16)",
        disabled: "rgba(0, 30, 60, 0.3)",
        disabledBackground: "rgba(0, 30, 60, 0.12)",
        focus: "rgba(0, 30, 60, 0.12)",
      },
    },
    typography: {
      h1: {
        fontSize: "2.5rem",
        fontWeight: 500,
        color: "black",
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 500,
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 500,
        color: "#000",
      },
      h4: {
        fontSize: 14,
        fontWeight: "500",
        color: "#000",
        fontFamily: "pretendardm",
      },
      h5: {
        fontSize: 14,
        fontWeight: "100",
        color: "#000",
        fontFamily: "pretendardr",
      },
      h6: {
        fontSize: 13,
        fontWeight: 500,
        color: "#6e6e6e",
        fontFamily: "pretendardr",
        letterSpacing: 1.2
      },
      subtitle1: {
        fontSize: 18,
        letterSpacing: "1px",
        fontFamily: "pretendardm",
        color: "black",
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "pretendardr",
        color: "black",
        letterSpacing: 1,
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        fontFamily: "pretendardr",
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        fontFamily: "pretendardr",
      },
      button: {
        textTransform: "none",
        fontWeight: "500",
        fontFamily: "pretendardr",
      },
      caption: {
        fontSize: 16,
        fontFamily: "pretendardm",
        color: "black",
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 4,
    },
    spacing: 4,
    components: {
      MuiStack: {
        defaultProps: {
          // borderColor: "#BDBDBD",
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: "15px",
            backgroundColor: "white",
            boxShadow: "none",
            border: "0.5px solid #d9d9d9",
            borderRadius: "8px",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          // color: 'error'
        },
        styleOverrides: {
          root: {
            fontFamily: "pretendardr",
            boxShadow: "none",
            // color:'#000000',
            // backgroundColor:'#ffffff',
            // textTransform: 'none',
            // borderColor: '#0000ff',
            // '&:hover': {
            //     borderColor: '#00ff00',
            // },
          },
        },
      },
      MuiTextField: {
        defaultProps: {},
        styleOverrides: {
          root: {},
        },
      },
    },
  },
  koKR
);

const darkTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#262430",
        contrastText: "#fff",
      },
      secondary: {
        main: "#dc004e",
        contrastText: "#000",
      },
      background: {
        default: "#313131",
        paper: "#5c5c5c",
      },
      text: {
        primary: "#fff",
        secondary: "#666",
        disabled: "#999",
      },
      error: {
        main: "#EE0000",
      },
      warning: {
        main: "#FFB724",
        contrastText: "#fff",
      },
      info: {
        main: "#29b6f6",
      },
      success: {
        main: "#37b75d",
      },
      action: {
        active: "#001E3C",
        hover: "rgba(0, 30, 60, 0.08)",
        selected: "rgba(0, 30, 60, 0.16)",
        disabled: "rgba(0, 30, 60, 0.3)",
        disabledBackground: "rgba(0, 30, 60, 0.12)",
        focus: "rgba(0, 30, 60, 0.12)",
      },
    },
    typography: {
      h1: {
        fontSize: "2.5rem",
        fontWeight: 500,
        color: "black",
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 500,
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 500,
        color: "#fff",
      },
      h4: {
        fontSize: 14,
        fontWeight: "500",
        color: "#fff",
        fontFamily: "pretendardm",
      },
      h5: {
        fontSize: 14,
        fontWeight: "100",
        color: "#fff",
        fontFamily: "pretendardr",
      },
      h6: {
        fontSize: 13,
        fontWeight: 500,
        color: "#fff",
        fontFamily: "pretendardr",
        letterSpacing: 1.2
      },
      subtitle1: {
        fontSize: 18,
        letterSpacing: "1px",
        fontFamily: "pretendardm",
        color: "#fff",
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "pretendardr",
        color: "#fff",
        letterSpacing: 1,
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        fontFamily: "pretendardr",
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        fontFamily: "pretendardr",
      },
      button: {
        textTransform: "none",
      },
      caption: {
        fontSize: 16,
        fontFamily: "pretendardm",
        color: "#fff",
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 4,
    },
    spacing: 4,
    components: {
      MuiStack: {
        defaultProps: {
          // borderColor: "#BDBDBD",
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: "15px",
            backgroundColor: "white",
            boxShadow: "none",
            border: "0.5px solid #d9d9d9",
            borderRadius: "8px",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          // color: 'error'
        },
        styleOverrides: {
          root: {
            fontFamily: "pretendardr",
            boxShadow: "none",
            color: "#fff",
            // backgroundColor:'#ffffff',
            // textTransform: 'none',
            // borderColor: '#0000ff',
            // '&:hover': {
            //     borderColor: '#00ff00',
            // },
          },
        },
      },
      MuiTextField: {
        defaultProps: {},
        styleOverrides: {
          root: {},
        },
      },
    },
  },
  koKR
);

export { darkTheme, lightTheme };
