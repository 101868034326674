import styled from "styled-components";

export const ChartWarpper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; 
  padding: 5px;
`;

export const ChartTitle = styled.span`
  font-family: pretendardm;
  font-weight: 500;
  font-size: 16px;
  padding-left: 8px; 
  padding-top: 5px;
`;

export const ChartContent = styled.div`
  flex: 1;
  /* background-color: greenyellow; */
  /* position: relative; */
  /* height: 90%; */
  /* padding: 50px; */
`;

export const TabMenu = styled.div`
  color: #75787b;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #dcdcdc;
  font-family: pretendardr;
  letter-spacing: 2px;

  .submenu {
    display: flex;
    /* height: 50px; */
    width: 130px;  height: 40px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
  }
 
  /* .submenu.focused:nth-child(1) {
    background-color: rgb(255, 255, 255);  
  } */

  .focused {
    color: #000; 
    border-top: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: none;
  }

  .not-focused {
    border-bottom: 1px solid #dcdcdc;
  }
`;

export const Desc = styled.div`
  padding: 0px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  height: 65px;
  width: 100%;
  justify-content: space-between;
`;
