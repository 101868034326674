import { PaletteMode } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

interface Props {
    mode: PaletteMode
}

const initialState: Props = {
    mode: 'light'
};

const envSlice = createSlice({
    name: "env",
    initialState,
    reducers: {
        setMode(state) {
            state.mode = state.mode === 'light' ? 'dark' : 'light';
        },
    },
});

export default envSlice;