import { useState } from "react";
import ApexChart from "react-apexcharts";
import { ChartContent, ChartTitle, ChartWarpper } from "../styled/CommonUI";

export default function ApexPieChart() {
  const [chartSeries, setChartSeries] = useState([50, 40, 35, 30]);

  return (
    <ChartWarpper>
      <ChartTitle>Pie Chart</ChartTitle>
      <ChartContent>
        <ApexChart
          series={chartSeries}
          type="pie"
          height={"230px"}
          options={{
            chart: {
              type: "pie",
              toolbar: {
                show: false,
              },
              fontFamily: "pretendardr",
              animations: {
                enabled: true,
                easing: "easein",
                speed: 1000,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            labels: ["SK", "Naver", "Kakao", "Samsung"],
            colors: ["#f43405", "#1bf32c", "#f3cf1b", "#077ad5"],
            dataLabels: {
              enabled: true,
              distributed: true,
              style: {
                fontFamily: "pretendardr",
                fontSize: "16px",
              },
            },
            legend: {
              show: true,
              position: "right",
              offsetX: 0,
              offsetY: 10,
              floating: false,
              // width:120
              // formatter: function (val, opts) {
              //   return val + " " + opts.w.globals.series[opts.seriesIndex];
              // },
            },
            plotOptions: {
              pie: {
                // offsetX: 0,
                // offsetY: -30,
                dataLabels: {
                  offset: -10,
                },
                expandOnClick: false,
                customScale: 0.8,
              },
            },
          }}
        />
      </ChartContent>
    </ChartWarpper>
  );
}
