import { Button } from "@mui/material";
import { useState } from "react";
import CustomConfirmDialog from "./CustomConfirmDialog";
import { MUI_COLOR_TYPE } from "../../config/Constants";

interface Props {
  title: string;
  variant: "text" | "outlined" | "contained";
  color: MUI_COLOR_TYPE;
  startIcon?: React.ReactNode;
  onClick: () => void;
  cTitle: string;
  cMessage: string;
}

export default function ConfirmButton({
  title,
  variant = "contained",
  color = "primary",
  startIcon,
  onClick,
  cTitle,
  cMessage,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = () => {
    setDialogOpen(false);
    onClick();
  };

  return (
    <>
      <Button
        variant={variant}
        color={color}
        startIcon={startIcon}
        onClick={() => setDialogOpen(true)}
      >
        {title}
      </Button>

      <CustomConfirmDialog
        open={dialogOpen}
        title={cTitle}
        message={cMessage}
        onConfirm={handleDelete}
        onCancel={() => setDialogOpen(false)}
        color={color}
      />
    </>
  );
}
