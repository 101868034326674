import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnResizeParams,
  GridDensity
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { popupCustomAlert } from "../components/CustomToast";
import ApexExampleChart from "../components/example/ApexExampleChart";
import { TabMenu } from "../components/styled/TabMenu";
import ConfirmButton from "../components/ui/ConfirmButton";
import DensitySelect from "../components/ui/DensitySelect";
import PageSizeSelect from "../components/ui/PageSizeSelect";
import ScreenContainer from "../components/ui/ScreenContainer";
import { CustomNoRowsOverlay } from "../lib/xdg/CustomNoRowsOverlay";
import { renderProgress } from "../lib/xdg/progress";
import renderCellExpand from "../lib/xdg/renderCellExpand";
import { RootState } from "../store/reducer";
import { formatNumber } from "../utils/stringUtils";
import { getBorderColor } from "../utils/uiUtils";
import { useParams } from "react-router-dom";

type CampaignData = {
  id: number;
  status: string;
  campaignName: string;
  campaignType: string;
  impressions: number;
  clicks: number;
  clickThroughRate: number;
  avgClickCost: string;
  totalCost: string;
  dailyBudget: string;
  u1: number;
  u2: number;
  u3: number;
};

export default function LinkScreen() {
  const theme = useTheme();
  const { mode } = useSelector((state: RootState) => state.envReducer);
  const [currentTab, clickTab] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [density, setDensity] = useState<GridDensity>("compact");
  const { groupId } = useParams();

  const menuArr = [
    { name: "키워드" },
    { name: "제외 검색어" },
    { name: "소재" },
    { name: "타겟팅" },
  ];

  const selectMenuHandler = (index: number) => {
    clickTab(index);
  };

  const COLUMN_WIDTHS_KEY = "dataGridColumnWidths";

  const getColumnWidthsFromLocalStorage = (): Record<string, number> => {
    // 로컬스토리지에서 열 너비 데이터를 가져옴
    const savedWidths = localStorage.getItem(COLUMN_WIDTHS_KEY);
    return savedWidths ? JSON.parse(savedWidths) : {};
  };

  // 기존 상태에서 열 너비를 LocalStorage에 저장하는 함수
  const handleColumnResize = (params: GridColumnResizeParams) => {
    const savedWidths = JSON.parse(
      localStorage.getItem(COLUMN_WIDTHS_KEY) || "{}"
    );
    savedWidths[params.colDef.field] = params.width;

    localStorage.setItem(COLUMN_WIDTHS_KEY, JSON.stringify(savedWidths));
  };

  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState<CampaignData[]>([]);

  useEffect(() => {
    setRows([
      {
        id: 1,
        status: "중지:캠페인 OFF",
        campaignName: "비딩이지_검색광고(PC)",
        campaignType: "파워링크",
        impressions: 0,
        clicks: 0,
        clickThroughRate: 0.1,
        avgClickCost: "0원",
        totalCost: "0원",
        dailyBudget: "20,000원",
        u1: 0,
        u2: 0,
        u3: 0,
      },
      {
        id: 2,
        status: "중지:캠페인 OFF",
        campaignName: "비딩이지_검색광고(MOB)",
        campaignType: "파워링크",
        impressions: 0,
        clicks: 0,
        clickThroughRate: 10,
        avgClickCost: "0원",
        totalCost: "0원",
        dailyBudget: "20,000원",
        u1: 0,
        u2: 0,
        u3: 0,
      },
      {
        id: 3,
        status: "중지:캠페인 OFF",
        campaignName: "쇼핑검색#1",
        campaignType: "쇼핑검색",
        impressions: 0,
        clicks: 0,
        clickThroughRate: 5,
        avgClickCost: "0원",
        totalCost: "0원",
        dailyBudget: "50원",
        u1: 0,
        u2: 0,
        u3: 0,
      },
      {
        id: 4,
        status: "중지:캠페인 OFF",
        campaignName: "파워컨텐츠#1",
        campaignType: "파워컨텐츠",
        impressions: 0,
        clicks: 0,
        clickThroughRate: 80,
        avgClickCost: "0원",
        totalCost: "0원",
        dailyBudget: "100원",
        u1: 0,
        u2: 0,
        u3: 0,
      },
      {
        id: 5,
        status: "노출가능",
        campaignName: "비딩원_검색광고",
        campaignType: "파워링크",
        impressions: 15175,
        clicks: 6,
        clickThroughRate: 21,
        avgClickCost: "5,966원",
        totalCost: "35,794원",
        dailyBudget: "[공유] 50,000원 (평균 25,000원)",
        u1: 0,
        u2: 0,
        u3: 0,
      },
      {
        id: 6,
        status: "노출가능",
        campaignName: "비딩원_검색광고(MOB)",
        campaignType: "파워링크",
        impressions: 3147,
        clicks: 3,
        clickThroughRate: 1.5,
        avgClickCost: "7,102원",
        totalCost: "21,307원",
        dailyBudget: "[공유] 50,000원 (평균 25,000원)",
        u1: 0,
        u2: 0,
        u3: 0,
      },
      {
        id: 7,
        status: "노출가능",
        campaignName: "비딩원_검색광고(MOB)",
        campaignType: "파워링크",
        impressions: 3147,
        clicks: 3,
        clickThroughRate: 15,
        avgClickCost: "7,102원",
        totalCost: "21,307원",
        dailyBudget: "[공유] 50,000원 (평균 25,000원)",
        u1: 0,
        u2: 0,
        u3: 0,
      },
    ]);
  }, []);

  const onClickDelete = () => {
    popupCustomAlert("success", "삭제 완료");
  };

  const processRowUpdate = async (
    newRow: CampaignData,
    oldRow: CampaignData
  ) => {
    const fieldChanged = Object.keys(newRow).find(
      (field) =>
        newRow[field as keyof CampaignData] !==
        oldRow[field as keyof CampaignData]
    );
    let newValue = newRow[fieldChanged as keyof CampaignData];
    let oldValue = oldRow[fieldChanged as keyof CampaignData];

    if (Number(newValue) === Number(oldValue)) {
      return oldRow;
    }

    if (!/^\d+$/.test(newValue.toString())) {
      popupCustomAlert("error", "숫자만 입력이 가능합니다");
      return oldRow;
    }

    popupCustomAlert(
      "success",
      `${fieldChanged} 업데이트 완료 (${newValue} , ${oldValue})`
    );
    return newRow;
  };

  function getRowId(row: CampaignData) {
    return row.id;
  }

  const columns: GridColDef<CampaignData>[] = [
    {
      field: "status",
      headerName: "상태",
      width: getColumnWidthsFromLocalStorage()["status"] || 100, // 로컬스토리지 값 또는 기본값 100
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style border-left",
      display: "flex",
      renderCell: (params) => <span>{params.row.status}</span>,
    },
    {
      field: "campaignName",
      headerName: "캠페인 이름",
      width: getColumnWidthsFromLocalStorage()["campaignName"] || 200,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      renderCell: renderCellExpand,
    },
    {
      field: "campaignType",
      headerName: "캠페인 유형",
      width: getColumnWidthsFromLocalStorage()["campaignType"] || 120,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style text-center",
      display: "flex",
      align: "center",
      renderCell: (params) => <span>{params.row.campaignType}</span>,
    },
    {
      field: "impressions",
      headerName: "노출 수",
      width: getColumnWidthsFromLocalStorage()["impressions"] || 100,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      align: "center",
      renderCell: (params) => (
        <span>{params.row.impressions.toLocaleString()}</span>
      ),
    },
    {
      field: "clicks",
      headerName: "클릭 수",
      width: getColumnWidthsFromLocalStorage()["clicks"] || 100,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      align: "center",
      renderCell: (params) => <span>{params.row.clicks.toLocaleString()}</span>,
    },
    {
      field: "clickThroughRate",
      headerName: "클릭률(%)",
      width: getColumnWidthsFromLocalStorage()["clickThroughRate"] || 100,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "text",
      renderCell: renderProgress,
    },
    {
      field: "avgClickCost",
      headerName: "평균 클릭비용 (VAT 포함)",
      width: getColumnWidthsFromLocalStorage()["avgClickCost"] || 150,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      align: "right",
    },
    {
      field: "totalCost",
      headerName: "총비용 (VAT 포함)",
      width: getColumnWidthsFromLocalStorage()["totalCost"] || 150,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      align: "right",
    },
    {
      field: "dailyBudget",
      headerName: "하루 예산",
      width: getColumnWidthsFromLocalStorage()["dailyBudget"] || 150,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      renderCell: renderCellExpand,
    },
    {
      field: "u1",
      headerName: "변경1",
      width: getColumnWidthsFromLocalStorage()["u1"] || 100,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      editable: true,
      valueGetter: (value, row) => formatNumber(row.u1),
      type: "number",
    },
    {
      field: "u2",
      headerName: "변경2",
      width: getColumnWidthsFromLocalStorage()["u2"] || 100,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      editable: true,
      valueGetter: (value, row) => formatNumber(row.u2),
      type: "number",
    },
    {
      field: "u3",
      headerName: "변경3",
      width: getColumnWidthsFromLocalStorage()["u3"] || 100,
      headerClassName: "grid-header-style",
      headerAlign: "center",
      cellClassName: "grid-cell-style",
      display: "flex",
      editable: true,
      valueGetter: (value, row) => formatNumber(row.u3),
      type: "number",
    },
  ];

  return (
    <ScreenContainer>
      <Stack flexDirection={"row"} height={300} gap={2}>
        <Stack
          display={"flex"}
          flex={1}
          height={"280px"}
          position={"relative"}
          bgcolor={theme.palette.background.paper}
          minWidth={"350px"}
        >
          <ApexExampleChart
            mode={mode}
            type="line"
            title={"광고 그룹 : 경쟁사 " + groupId}
            height={250}
          />
        </Stack>
        <Stack
          display={"flex"}
          width={"350px"}
          height={"280px"}
          bgcolor={theme.palette.background.paper}
        >
          <Stack flex={1} flexDirection={"column"}>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              height={"50px"}
              borderBottom={getBorderColor(mode)}
              pl={4}
            >
              <Typography variant="caption">광고그룹 정보</Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              pl={4}
              flex={1}
              bgcolor={"#fcfcfc"}
            >
              <Box flex={2}>
                <Typography variant="h4">상태</Typography>
              </Box>
              <Box flex={3}>
                <Typography variant="h5">노출 가능</Typography>
              </Box>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} pl={4} flex={1}>
              <Box flex={2}>
                <Typography variant="h4">채널 정보</Typography>
              </Box>
              <Box flex={3}>
                <Typography variant="h5">biddingwin.co.kr</Typography>
              </Box>
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              pl={4}
              flex={1}
              bgcolor={"#fcfcfc"}
            >
              <Box flex={2}>
                <Typography variant="h4">기본 입찰가</Typography>
              </Box>
              <Box flex={3}>
                <Typography variant="h5">70 원</Typography>
              </Box>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} pl={4} flex={1}>
              <Box flex={2}>
                <Typography variant="h4">하루 예산</Typography>
              </Box>
              <Box flex={3}>
                <Typography variant="h5">사용 안함</Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack style={{ flex: 1 }}>
        {/* <TabMenu mode={mode}>
          {menuArr.map((el, index) => (
            <li
              className={
                index === currentTab
                  ? "submenu focused"
                  : "submenu not-focused "
              }
              onClick={() => selectMenuHandler(index)}
            >
              {el.name}
            </li>
          ))}
        </TabMenu> */}
        <Stack flexDirection={"row"} gap={1} mb={2}>
        {menuArr.map((el, index) => (
            <Button
            variant="contained"
            size="medium"
            sx={{
              width:110,
              backgroundColor:  index === currentTab ? '' : 'white',
              color:  index === currentTab ? 'white' : 'black',
              letterSpacing:'1px'
            }}
              onClick={() => selectMenuHandler(index)}
            >
              {el.name}
            </Button>
          ))}
        </Stack>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          // borderLeft={getBorderColor(mode)}
          // borderRight={getBorderColor(mode)}
          paddingX={4}
          paddingY={3}
          alignItems={"center"}
          style={{ backgroundColor: theme.palette.background.paper }}
        >
          <div className="flex-box" style={{ gap: 5 }}>
            <Button variant="contained" startIcon={<AddOutlinedIcon />}>
              새 키워드
            </Button>
            <Button
              variant="contained"
              color="success"
              style={{
                color: "white",
              }}
            >
              입찰가 변경
            </Button>
            <ConfirmButton
              title="삭제"
              variant="contained"
              color="error"
              startIcon={<RiDeleteBinLine />}
              onClick={onClickDelete}
              cTitle="삭제 확인"
              cMessage="정말 삭제하시겠습니까?"
            />
          </div>
          <Stack
            flexDirection={"row"}
            gap={1}
            alignContent={"center"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={() =>
                popupCustomAlert("excel", "엑셀 다운로드를 진행합니다")
              }
            >
              엑셀 다운로드
            </Button>
            <DensitySelect value={density} onChange={setDensity} />
            <PageSizeSelect
              key={"pageSizeSelectKey"}
              value={pageSize}
              onChange={setPageSize}
              options={[
                {
                  value: 10,
                  label: "10개씩 보기",
                },
                {
                  value: 20,
                  label: "20개씩 보기",
                },
              ]}
            />
          </Stack>
        </Stack>
        <DataGrid
          getRowId={getRowId}
          rows={rows}
          columns={columns}
          columnHeaderHeight={56}
          density={density}
          onDensityChange={(newDensity) => setDensity(newDensity)}
          checkboxSelection
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            popupCustomAlert("error", "오류 발생~");
            console.error("수정 처리 중 오류:", error);
          }}
          // onClipboardCopy={(copiedString) =>
          //   setCopy(cleanIfNumberWithCommas(copiedString))
          // }
          onColumnResize={handleColumnResize}
          onColumnWidthChange={handleColumnResize}
          sx={{
            // height: "43vh",
            maxHeight: 380,
            // flex:1,
            backgroundColor: theme.palette.background.paper,
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-columnHeader": {
              // backgroundColor: theme.palette.primary.light,
            },
            "&.MuiDataGrid-root": {
              borderRadius: 0,
              border: "none",
              borderTop: getBorderColor(mode),
              borderBottom: getBorderColor(mode),
            },
            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
        <Stack
          alignItems={"center"}
          pt={1}
          pb={1}
          bgcolor={theme.palette.background.paper}
          // borderBottom={getBorderColor(mode)}
          // borderLeft={getBorderColor(mode)}
          // borderRight={getBorderColor(mode)}
          flexDirection={"row"}
          justifyContent={"space-between"}
          pl={3}
          pr={3}
        >
          <Typography variant="h5">전체 {rows.length} 건</Typography>
          <Pagination
            shape="rounded"
            count={10}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                color="standard"
                variant="text"
                shape="rounded"
                sx={{
                  "&.Mui-selected": {
                    fontWeight: "600",
                    backgroundColor: "transparent",
                    border: getBorderColor(mode),
                  },
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </ScreenContainer>
  );
}
