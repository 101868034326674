import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import { SIDE_BAR_WIDTH } from "../../config/Constants";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateIcon from "@mui/icons-material/Update";
import { popupCustomAlert } from "../CustomToast";
import { errorHandler, http } from "../../config/axios-config";

const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
`;

const LogoImage = styled.img`
  height: 70px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${SIDE_BAR_WIDTH};
  min-width: 220px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 12px 15px;
  user-select: none;
  cursor: pointer;
`;

const MidMenu = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-family: pretendardr;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 5px;
  user-select: none;
  cursor: pointer;
`;

const SyncButton = styled.button`
  border: none; /* 테두리 제거 */
  background-color: transparent;
  display: flex;
  font-size: 16px; /* 글자 크기 */
  cursor: pointer; /* 마우스 포인터 변경 */
  outline: none; /* 포커스 시 기본 테두리 제거 */
  transition: all 0.3s ease; /* 부드러운 전환 효과 */

  &:hover {
    color: blue; /* 호버 시 텍스트 색상 변경 */
    opacity: 0.8; /* 호버 시 살짝 투명 효과 */
  }

  &:active {
    transform: scale(0.95); /* 클릭 시 살짝 작아짐 */
  }

  &:disabled {
    color: gray; /* 비활성화 시 텍스트 색상 변경 */
    cursor: not-allowed; /* 비활성화 상태 마우스 포인터 */
  }
`;

const BottomMenu = styled.li`
  list-style: none;
  font-size: 14px;
  font-family: pretendardr;
  padding: 8px 20px;
  color: #7e7e7e;
  user-select: none;
  cursor: pointer;
  background-color: white;

  &:hover {
    color: #000;
  }
`;

const MenuList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export default function SideBar() {
  const { mode } = useSelector((state: RootState) => state.envReducer);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedCategories, setExpandedCategories] = useState<string[]>([
    "common",
    "link",
    "content",
  ]);

  const toggleCategory = (category: string) => {
    setExpandedCategories(
      (prev) =>
        prev.includes(category)
          ? prev.filter((cat) => cat !== category) // 이미 열려 있으면 닫기
          : [...prev, category] // 닫혀 있으면 추가하기
    );
  };

  const onClickMenu = (path: string) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate(path);
  };

  const onClickSync = async (type: "link" | "content") => {
    if (type === "link") {
      try {
        await http.post("/cmp/sync/");
        popupCustomAlert("success", "캠페인 동기화를 완료했습니다");
      } catch (error) {
        errorHandler(error);
      }
    } else if (type === "content") {
    }
  };

  return (
    <Container>
      <LogoWrap>
        <LogoImage src={logo} alt="Logo" />
      </LogoWrap>

      <Stack
        sx={{
          flex: 1,
          backgroundColor: mode === "light" ? "#576071" : "#323333",
          padding: 0,
        }}
      >
        <MenuList>
          <Stack>
            <TopMenu
              onClick={() => toggleCategory("common")}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
              }}
            >
              공통
              {expandedCategories.includes("common") ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
            </TopMenu>
            {expandedCategories.includes("common") && (
              <MenuList>
                {[
                  { title: "Dashboard", link: "/" },
                  { title: "Mui", link: "/mui" },
                ].map((item) => (
                  <BottomMenu
                    key={item.title}
                    onClick={() => onClickMenu(item.link)}
                    style={{
                      color:
                        location.pathname === item.link
                          ? theme.palette.primary.main
                          : "#7e7e7e",
                    }}
                  >
                    {item.title}
                  </BottomMenu>
                ))}
              </MenuList>
            )}
          </Stack>

          <Stack>
            <TopMenu
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
              }}
            >
              <Stack flex={1} flexDirection={"row"}>
                <Stack
                  flex={1}
                  onClick={() => toggleCategory("link")}
                  justifyContent={"center"}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#fff",
                    }}
                  >
                    파워링크
                  </Typography>
                </Stack>
                <SyncButton onClick={() => onClickSync("link")}>
                  <UpdateIcon
                    fontSize="small"
                    sx={{
                      color: "white",
                    }}
                  />
                </SyncButton>
              </Stack>
              {expandedCategories.includes("link") ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
            </TopMenu>
            {expandedCategories.includes("link") && (
              <Stack>
                <MidMenu
                  onClick={() => toggleCategory("lc1")}
                  style={{
                    backgroundColor: "#c6d8fc",
                    color: "#000",
                  }}
                >
                  캠페인11
                </MidMenu>
                {expandedCategories.includes("lc1") && (
                  <MenuList>
                    <BottomMenu
                      onClick={() => navigate("/link/group1")}
                      style={{
                        color:
                          location.pathname === "/link/group1"
                            ? theme.palette.primary.main
                            : "#7e7e7e",
                      }}
                    >
                      그룹1
                    </BottomMenu>
                    <BottomMenu
                      onClick={() => navigate("/link/group2")}
                      style={{
                        color:
                          location.pathname === "/link/group2"
                            ? theme.palette.primary.main
                            : "#7e7e7e",
                      }}
                    >
                      그룹2
                    </BottomMenu>
                  </MenuList>
                )}
              </Stack>
            )}
          </Stack>
        </MenuList>
      </Stack>
    </Container>
  );
}
