import axios from "axios";
import { AxiosError } from "axios";
import { popupCustomAlert } from "../components/CustomToast";
// import { SERVER_URL } from "../constants/constant";
// import { clearJwtState, getJwtState } from "../utils/jwtState";
// import { LoginAPI } from "../service/login/loginAxios";

// 원격 서버
export const URL = "https://biddingeasy.co.kr/api2";
// export const URL = "http://192.168.0.13:8000/api2";

export const http = axios.create({
  baseURL: URL,
  withCredentials: true,
});

http.interceptors.request.use(
  //axios 요청 직전에 발생하는 함수
  function (config) {
    return config;
  },
  //axios 요청 에러나면 발생하는 함수
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  //axios 올바른 response가오면 발동하는 함수
  function (response) {
    return response;
  },

  //에러 메시지가 response되면 발동되는 함수
  async function (error) {
    console.log(error?.response?.data?.error?.message);
    if (
      error?.response?.data?.error?.message.includes(
        "(인증 토큰 값 유효하지 않음)"
      ) ||
      error?.response?.data?.error?.message.includes("권한 없음")
    ) {
      console.log("토큰 만료라 리프레쉬 토큰 함수 동작함");
      //   LoginAPI.refreshToken();
      //   // 예: 인증 오류 발생 시 로그아웃 처리
      //   clearJwtState();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const isAxiosError = <HttpErrorResponseType>(
  error: unknown
): error is AxiosError<HttpErrorResponseType> => {
  return axios.isAxiosError(error);
};

export interface HttpErrorResponseType {
  data: any;
  message: string;
  status: string;
}

export const errorHandler = (error: unknown) => {
  if (isAxiosError<HttpErrorResponseType>(error)) {
    const status = error.response?.status || 0;
    const message = error.response?.data.message || "";

    if (status === 403) {
      popupCustomAlert(
        "warn",
        "로그인 유효시간이 만료되었습니다",
        "expiredSession"
      );
    } else {
      popupCustomAlert("warn", message);
    }
  } else {
    popupCustomAlert("error", "예상치 못한 오류 발생");
  }
};
