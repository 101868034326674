import { PaletteMode } from "@mui/material";
import { useState } from "react";
import ApexChart from "react-apexcharts";
import { ChartContent, ChartTitle, ChartWarpper } from "../styled/CommonUI";

interface AreaChartProps {
  mode: PaletteMode;
  type: "area" | "line";
  title?: string;
  height: number | string;
}

export default function ApexExampleChart({
  mode,
  type,
  title = "",
  height = "250px",
}: AreaChartProps) {
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Sales",
      data: [299, 330, 331, 192, 152, 270, 243],
    },
  ]);

  const categories = [
    "11.01.",
    "11.02.",
    "11.03.",
    "11.04.",
    "11.05.",
    "11.06.",
    "11.07.",
  ];

  return (
    <ChartWarpper>
      <ChartTitle>{title}</ChartTitle>
      <ChartContent>
        <ApexChart
          series={chartSeries}
          type={type}
          // width={300}
          height={height}
          options={{
            chart: {
              // background:'red',
              // offsetY: 10,
              offsetX: -5,
              zoom: {
                enabled: true,
              },
              toolbar: {
                show: true,
                offsetY: -20,
                tools: {
                  download: true,
                  pan: false,
                  reset: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                },
              },
              animations: {
                enabled: true,
                easing: "easeout",
                speed: 1000,
                animateGradually: {
                  enabled: false,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            dataLabels: {
              enabled: false,
              background: {
                borderWidth: 0,
                borderRadius: 20,
                enabled: true,
                dropShadow: {
                  blur: 5,
                  color: "#4180d9",
                  enabled: true,
                  left: 3,
                  opacity: 5,
                  top: 2,
                },
              },
              textAnchor: "middle",
              distributed: true,
            },
            stroke: {
              show: true,
              colors: ["#6ba4f5"],
              curve: "straight",
              width: [2],
              // dashArray: [0],
            },
            plotOptions: {
              pie: {
                // offsetX: 0,
                // offsetY: 10,
              },
            },
            // fill: {
            //   type: "gradient", // 'gradient' / 'solid' / 'pattern' / 'image',
            //   colors: ["#5981d6"],
            // },
            markers: {
              size: 0,
              // size: [20],
              shape: "circle",
              hover: {
                sizeOffset: 6,
              },
            },
            xaxis: {
              categories,
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              tickAmount: 4,
              min: 0,
              tooltip: {
                enabled: false,
              },
            },
            tooltip: {
              enabled: true,
              enabledOnSeries: undefined,
              shared: false,
              followCursor: false,
              inverseOrder: false,
              // custom: () => {
              //   return `<div style={{color:"red"}}>asdasd</div>`
              // },
              hideEmptySeries: false,
              fillSeriesColor: false,
              style: {
                fontSize: "12px",
              },
              onDatasetHover: {
                highlightDataSeries: false,
              },
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  '<div class="flex-box-column tooltip-container">' +
                  '<div class="tooltip-title">' +
                  categories[dataPointIndex] +
                  "</div>" +
                  '<div class="flex-box tooltip-content"><div>노출수</div>' +
                  series[seriesIndex][dataPointIndex] +
                  "</div>" +
                  "</div>"
                );
              },
              x: {
                show: true,
                // format: "dd MMM",
                formatter: undefined,
              },
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return "노출수";
                    },
                  },
                },
              ],
              marker: {
                show: true,
              },
              fixed: {
                enabled: false,
                position: "right",
                offsetX: 0,
                offsetY: 0,
              },
            },
            grid: {
              // borderColor: "#e09797",
              column: {
                // colors: ["#0000ff", "#00ff00", "#ff0000"],
                // opacity: 5,
              },
              padding: {
                // top: 20,
                // bottom: 0,
                // left: 20,
                // right: 20,
              },
            },
          }}
        />
      </ChartContent>
    </ChartWarpper>
  );
}
