import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Stack, Typography } from "@mui/material";
import { FiInfo } from "react-icons/fi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { toast, ToastOptions } from "react-toastify";

export const toastOptions: ToastOptions = {
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

interface CustomToastProps {
  msg: string;
  type: CustomAlertType;
}

type CustomAlertType = "success" | "error" | "info" | "warn" | "excel";

export const popupCustomAlert = (
  type: CustomAlertType,
  message: string,
  toastId = ""
) => {
  if (toastId === "") {
    toast(<CustomToast type={type} msg={message} />, {
      ...toastOptions,
    });
  } else {
    toast(<CustomToast type={type} msg={message} />, {
      ...toastOptions,
      toastId,
    });
  }
};

const getIcon = (type: CustomAlertType) => {
  if (type === "warn") {
    return (
      <NotificationsNoneIcon
        style={{
          color: "#f57119",
        }}
      />
    );
  } else if (type === "error") {
    return (
      <ErrorOutlineOutlinedIcon
        style={{
          color: "#f84d4d",
        }}
      />
    );
  } else if (type === "success") {
    return (
      <IoCheckmarkCircleOutline
        fontSize={22}
        style={{
          color: "#3f56bb",
        }}
      />
    );
  } else if (type === "info") {
    return (
      <FiInfo
        fontSize={22}
        style={{
          color: "#626be7",
        }}
      />
    );
  } else if (type === "excel") {
    return (
      <DownloadIcon
        style={{
          color: "#626be7",
        }}
      />
    );
  }

  return (
    <CheckCircleOutlinedIcon
      style={{
        color: "#3f56bb",
      }}
    />
  );
};

export const CustomToast = ({ type, msg }: CustomToastProps) => {
  // return <ToastContainer />
  return (
    <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
      {getIcon(type)}
      <Typography variant="h5">{msg}</Typography>
    </Stack>
  );
};
