import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  email: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLogin(state, action) {
      state.username = action.payload.username;
      state.email = action.payload.email;
    },
    setUserLogout(state) {
      state.username = "";
      state.email = "";
    },
  },
});

export default userSlice;
