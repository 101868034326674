import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { Bounce, Flip, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import AppInner from "./AppInner";
import { darkTheme, lightTheme } from "./config/theme";
import "./index.css";
import { persistor } from "./store";
import { RootState } from "./store/reducer";

function App() {
  const { mode } = useSelector((state: RootState) => state.envReducer);

  return (
    <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
      <CssBaseline />
      <PersistGate loading={null} persistor={persistor}></PersistGate>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ToastContainer
          autoClose={2000}
          limit={5}
          position="top-center"
          hideProgressBar={true}
          transition={Bounce}
          pauseOnFocusLoss={false}
          theme="light"
        />
        <AppInner />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
