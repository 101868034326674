import { combineReducers } from "redux";
import userSlice from "../slices/user";
import envSlice from "../slices/env";


const rootReducer = combineReducers({
    userReducer: userSlice.reducer,
    envReducer: envSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;